<template>
  <div class="relative">
    <div v-if="!isMobile" @click="filterOpen"
         class="px-[12px] w-[125px] h-[40px] text-[14px] text-osn-grey-02 border border-osn-grey-03 rounded-full
   flex items-center justify-between cursor-pointer">
      <svg-icon name="filter"
                size="20"
                class="mr-[10px]"></svg-icon>
      <span v-if="!filterString">{{ $t('filter.title') }}</span>
      <div v-else
           class=" px-[4px] text-[16px] text-osn-dark-grey-00
      border border-osn-dark-grey-00 rounded-full inline-flex items-center">
        <div class="w-[50px] max-w-full truncate">{{ filterString }}</div>
        <el-icon @click="handleClear">
          <Close/>
        </el-icon>
      </div>
      <svg-icon name="arrow_down"
                size="20"
                class="flex-1">
      </svg-icon>
    </div>
    <div v-else
         class="filter-btn"
         @click="filterOpen">
      <span v-if="filterNumber">{{ filterNumber }}</span>
      <img src="@/assets/image/icon/menu/tune.svg" alt="">
    </div>

    <Filter ref="compFilter"></Filter>
  </div>

</template>
<script setup>
import {computed, ref} from "vue";
import {Close} from '@element-plus/icons-vue'

import Filter from "@/components/MenuNav/Filter.vue";
import {useFilterStore} from "@/stores/filter.js";
import {useSettingStore} from "@/stores/setting.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";

const settingStore = useSettingStore()
const filterStore = useFilterStore();
const searchQueryStore = useSearchQueryStore()


const compFilter = ref(null);
const isMobile = computed(() => settingStore.isMobile);

const selectedTypeName = computed(() => {
  return filterStore.getSelectedCategoryTypeName.join(',')
})

const selectedTagName = computed(() => {
  return filterStore.getSelectedCategoryTagName.join(',')
})
const selectedEquipmentName = computed(() => {
  return filterStore.getSelectedEquipmentName.join(',')
})

const selectedServiceName = computed(() => {
  return filterStore.getSelectedServiceName.join(',')
})

const filterNumber = computed(() => {
  return filterStore.getFilterSelectedTotal;
});

// todo 暫時先顯示，等設計之後要改
const filterString = computed(() => {
  const filters = [selectedTypeName.value,selectedTagName.value, selectedEquipmentName.value, selectedServiceName.value].filter(Boolean);

  if (filters.length > 0) {
    return filters.join(', ');
  } else {
    return '';
  }
});


const filterOpen = () => {
  compFilter.value.show()
}

function handleClear(event) {

  // todo filter store 是不是selected的地方要合併到searchQueryStore，目前是搜尋才從filter store存入
  event.stopPropagation();
  filterStore.clearFilterSelected();
}
</script>
<style scoped
       lang="scss">
.filter-btn {
  @apply relative min-w-[50px] h-[42px] bg-osn-yellow-00 rounded-[50px] flex justify-center items-center cursor-pointer shadow-md;

  span {
    @apply absolute top-[-5px] right-[-5px] w-[24px] h-[24px] leading-[24px] rounded-full text-[13px]
    text-osn-white-00 bg-osn-dark-grey-00 text-center ;
  }
}

</style>
