<template>
  <div class="filter-group">
    <div class="filter-group-title">{{ title }}</div>
    <ul class="filter-group-content">
      <li class="filter-group-content-item"
          v-for="(item, index) in list"
          :key="index"
          :class="{'filter-group-content-item-active': item.selected}"
          @click="handleClick(item)">
        {{ displayName(item) }}
        <!--        <el-icon v-if="item.selected" :size="12"><Select/></el-icon>-->
      </li>
    </ul>
  </div>
</template>
<script setup>
import {defineProps} from "vue";
import {Select} from "@element-plus/icons-vue";


const props = defineProps({
  title: {
    type: String
  },
  list: {
    type: Array
  },
  handleClick: {
    type: Function
  }
})

const displayName = (item) => {
  return item.equipment_name || item.service_name || item.tag_name || item.name
}


</script>
<style scoped
       lang="scss">
.filter-group {
  .filter-group-title {
    @apply my-[12px] text-[18px] font-semibold text-osn-dark-grey-00;
  }

  .filter-group-content {
    @apply flex flex-wrap;
    .filter-group-content-item {
      @apply mr-[6px] mb-[6px] px-[8px] h-[30px] leading-[27px] text-[14px] text-osn-black-00
      rounded-[50px] border border-osn-yellow-00 cursor-pointer;
      &-active {
        @apply bg-osn-yellow-00;
      }
    }

  }

}

</style>
