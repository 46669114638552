<template>
  <el-select
      v-model="selectedItem"
      @change="selectOptionChange"
      class="text-[14px] text-osn-grey-02 type-dropdown border rounded-full"
      placement="bottom"
      :placeholder="filterName"
  >
    <el-option
        v-for="item in optionList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
    />
  </el-select>
</template>

<script setup
        lang="ts">
import {onMounted} from "vue";

const filterName = defineModel('filterName', { type: String, default: '' });
const optionList = defineModel('optionList', { type: Array, default: () => [] });
const rangeConfig = defineModel('rangeConfig', { type: Object, default: () => ({}) });
const initialRange = defineModel('initialRange', { type: Object, default: () => ({}) });

const emit = defineEmits(['update:selectedItem']);

const selectedItem = ref(0)


onMounted(() => {

  const { min: newMin, max: newMax } = initialRange.value;

  Object.keys(rangeConfig.value).forEach((key) => {
    const {'min': minPrice, 'max': maxPrice} = rangeConfig.value[key];
    if (newMin === minPrice && newMax === maxPrice) {
      selectedItem.value = Number(key);
    }
  });
})

const selectOptionChange = (value: number) => {
  if(value===undefined||value===null){
    return
  }
  emit('update:selectedItem', value);
}

</script>

<style scoped
       lang="scss">

.type-dropdown {
  @apply cursor-pointer;
  --el-select-input-focus-border-color: '#FFFFFF';

  :deep(.el-select__wrapper) {
    @apply w-[130px] h-[38px];
    background-color: transparent;
    border-radius: 0;
    box-shadow: 0 0 0 0;
  }
}
</style>
